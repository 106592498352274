import BaseAPIClient from "./api";
import { AppConstants } from "../constants/AppConstants";
import axios from "axios";

const globalHeaders: any = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const API_BASE_URL: string =
  process.env.REACT_APP_API_URL || "http://localhost";
const API = BaseAPIClient?.axiosInstance;

const CUSTOMER_QUERY_HISTORY = "/customer-query-history"

export const getPastQueryAnalysisRequests = async (
    userId: any,
    pageNo: number,
    pageSize: number
  ) => {
    const headers = { ...globalHeaders };
  
    let uri: string = `${API_BASE_URL}${CUSTOMER_QUERY_HISTORY}/customer-query-insights?pageNumber=${pageNo}&pageSize=${pageSize}&userId=${userId}`;
  
    try {
      const response = await API.get(uri, { headers });
      return response;
    } catch (error) {
      console.error("Error fetching past query analysis requests:", error);
      throw error;
    }
  };



  export const submitQueryAnalysisRequest = async (
    apiKey: string,
    productName: string,
    startDate: string,
    endDate: string
  ) => {
    const headers = { ...globalHeaders };
  
    const uri: string = `${API_BASE_URL}${CUSTOMER_QUERY_HISTORY}/submit`;
  
    try {
      const response = await API.post(
        uri,
        null, // No body is needed, as we're passing params in the query string
        {
          headers,
          params: {
            apiKey,
            productName,
            startDate,
            endDate,
          },
        }
      );
  
      return response;
    } catch (error) {
      console.error("Error submitting query analysis request:", error);
      throw error;
    }
  };
  
  
  export const getUserApiKeys = async (userId: string) => {
    const headers = { ...globalHeaders };
  
    const uri: string = `${API_BASE_URL}/subscription/get-user-keys-and-product?userId=${userId}`;
  
    try {
      const response = await API.get(uri, { headers });
      return response.data;
    } catch (error) {
      console.error("Error fetching user API keys:", error);
      throw error;
    }
  };
  