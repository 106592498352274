import { useState, useEffect } from "react";
import TrestleTable, {
  TableHeaderProps,
} from "../../Shared/TrestleTable/TrestleTable";
import InputTextField from "../../Shared/InputTextField";
import { ComponentLabelHeader } from "../../Shared/CommonComponents";
import {
  changeProductAccess,
  getManageProductAccess,
} from "../../../http/admin-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import moment from "moment";
import TrestleButton from "../../Shared/TrestleButton";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import LoaderComponent from "../../Shared/LoaderComponent";
import NoDataFound from "../../Shared/NoDataFound";
import DropDown from "../../Shared/DropDown";
import { find } from "lodash";
import WaringPopup from "../../Shared/WarningPopup";
import { getUTCDate } from "../../../utils/helpers";
import { Tooltip } from "@mui/material";
import { InfoIcon } from "../../../assets/svgs";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { useAppSelector } from "../../../app/hooks";
import UserDrawer from "../UserDrawer/UserDrawer";
import { ActionButtonContainer } from "../../Shared/PaymentWallet/WalletComponets/Styles";
import ApiAccessRequestPopUp from "../../Shared/ApiAccessRequestPopUp";

interface ManageProductAccess {
  userId: number;
  email: string;
  name: string;
  productName: string;
  usagePlanId: number;
  subscriptionId: number;
  requestedDate: EpochTimeStamp;
  description: string;
}

function ProductAccess() {
  const [expanded, setExpanded] = useState<string | false>("");
  const [searchData, setSearchData] = useState<any>({
    searchString: "",
    searchKey: "User Name",
  });
  const [productAccess, setProductAccess] = useState<ManageProductAccess[]>([]);
  const [productAccessFilter, setProductAccessFilter] = useState<
    ManageProductAccess[]
  >([]);

  const [isDescriptionPopUpOpen, setIsDescriptionPopUpOpen] =
    useState<boolean>(false);

  const [currentRowData, setCurrentRowData] = useState<ManageProductAccess>({
    userId: 0,
    email: "",
    name: "",
    productName: "",
    usagePlanId: 0,
    subscriptionId: 0,
    requestedDate: 0,
    description: "",
  });

  const [Loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const SearchKeyOption = [
    {
      label: "User Name",
      key: "name",
    },
    {
      label: "Product",
      key: "productName",
    },
    {
      label: "User Id",
      key: "userId",
    },
  ];

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  useEffect(() => {
    window.SetMode("ADMIN");
    getProductAccess();
    handleMixPanel(MixPanelEvents.VISIT_ADMIN_PRODUCT_ACCESS, {
      userId: userData?.id,
      userEmail: userData?.email,
    });
  }, []);

  useEffect(() => {
    if (searchData.searchString) {
      OnSearchChange(searchData.searchString);
    } else {
      setProductAccessFilter(productAccess);
    }
  }, [productAccess]);

  const getProductAccess = async () => {
    setLoading(true);
    const response = await getManageProductAccess({
      pageNumber: 1,
      noOfRecords: 5000,
    });
    setLoading(false);
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setProductAccess(data.data);
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const showDenyPopUp = (data: ManageProductAccess) => {
    setOpenPopup(true);
    setCurrentRowData(data);
  };

  const showDescriptionPopUp = (data: ManageProductAccess) => {
    setIsDescriptionPopUpOpen(true);
    setCurrentRowData(data);
  };

  const changeAccess = async (type: string, reqData: ManageProductAccess) => {
    let request = {
      email: reqData.email,
      usagePlanId: reqData.usagePlanId,
      subscriptionId: reqData.subscriptionId,
      status: type,
    };

    setLoading(true);
    const response = await changeProductAccess(request);
    setLoading(false);
    if (response?.status === HTTP_STATUS.HTTP_OK) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg:
            type === "GRANT"
              ? AppConstants.PRODUCT_ACCESS_GRANTED
              : AppConstants.PRODUCT_ACCESS_DENIED,
        })
      );
      getProductAccess();
    } else {
      const { details } = response.data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const headCells: readonly TableHeaderProps[] = [
    {
      id: "userId",
      numeric: true,
      disablePadding: false,
      label: "User ID",
      width: "10%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      width: "20%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <UserDrawer
            userName={`${ele.name || "-"}`}
            userData={{ id: ele.userId, email: ele.email }}
          />
        );
      },
      sortable: true,
    },
    {
      id: "productName",
      numeric: false,
      disablePadding: false,
      label: "Product",
      width: "20%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "requestedDate",
      numeric: false,
      disablePadding: false,
      label: (
        <div className="flex">
          <div>Requested On</div>
          <Tooltip title={"Date is in UTC"} arrow sx={{ width: "100px" }}>
            <span className="ml-[5px]">{InfoIcon}</span>
          </Tooltip>
        </div>
      ),
      width: "17%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <span>
            {moment(getUTCDate(ele.requestedDate)).format("YYYY-MM-DD HH:mm")}
          </span>
        );
      },
      sortable: true,
    },
    {
      id: "access",
      numeric: false,
      disablePadding: false,
      label: "Access",
      width: "33%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <div className="flex">
            <TrestleButton
              onClick={() => {
                changeAccess("GRANT", ele);
              }}
              label="Grant"
              type="primary"
              className="!max-w-[100px] !min-w-[100px] !h-[40px] !py-0 !pt-0 mr-5 bg-[#D9F1EA] !text-[#00B47E] font-DMSans"
            />
            <TrestleButton
              onClick={() => {
                showDenyPopUp(ele);
              }}
              label="Deny"
              type="primary"
              className="!max-w-[100px] !min-w-[100px] !h-[40px] !py-0 !pt-0 mr-5 bg-[#F8F1F1] !text-[#B23352] font-DMSans"
            />
            {ele?.description !== "null" && (
              <ActionButtonContainer
                onClick={() => {
                  showDescriptionPopUp(ele);
                }}
                style={{ textDecoration: "underline" }}
              >
                Reason
              </ActionButtonContainer>
            )}
          </div>
        );
      },
      sortable: false,
    },
  ];

  const onInputValueChange = (ele: any) => {
    const { value, name } = ele.target;
    setSearchData({ ...searchData, [name]: value });
    if (name === "searchString") {
      OnSearchChange(value);
    }
  };

  const OnSearchChange = (str: string) => {
    const { searchKey = "User Name" } = searchData;
    let sKey: any = find(SearchKeyOption, { label: searchKey }) || {};
    const { key = "name" } = sKey;

    let data = productAccess.filter((item: any) => {
      return key === "userId"
        ? item[key].toString().toLowerCase().startsWith(str.toLowerCase())
        : item[key].toString().toLowerCase().includes(str.toLowerCase());
    });
    setProductAccessFilter(data);
  };

  return (
    <>
      <LoaderComponent loader={Loading} />
      <div className="flex p-[50px] bg-[#F5F5F5]">
        <ComponentLabelHeader className="flex-1">
          Manage Product Access
        </ComponentLabelHeader>
      </div>
      <div className="bg-white h-fit px-[50px] pb-[20px]">
        <div className="flex py-[45px]">
          <div className="text-[20px] font-medium text-[#163545] leading-[50px]">
            Pending
          </div>
          <div className="flex w-full">
            <div className="max-w-[180px] w-full ml-auto mr-5">
              <DropDown
                name={"searchKey"}
                label={""}
                value={searchData["searchKey"]}
                error={""}
                placeholder={"Search"}
                onChange={(val: any) => {
                  setSearchData({
                    ...searchData,
                    searchKey: val,
                    searchString: "",
                  });
                  setProductAccessFilter(productAccess);
                }}
                required={false}
                options={SearchKeyOption.map((key) => key.label)}
                className="!h-[50px] text-[#163545]"
              />
            </div>
            <div className="max-w-[400px] w-full">
              <InputTextField
                name={"searchString"}
                label={""}
                placeholder="Search"
                value={searchData}
                error={{}}
                type={"text"}
                onChange={onInputValueChange}
                required={false}
                maxLength={AppConstants.MAX_NAME_LENGTH}
                height="50px"
                searchable={true}
              />
            </div>
          </div>
        </div>
        <div>
          {productAccessFilter.length > 0 ? (
            <TrestleTable
              isPaginated={false}
              headCells={headCells}
              rows={productAccessFilter}
              clientSort={true}
            />
          ) : (
            !Loading && <NoDataFound className="py-52" />
          )}
        </div>
      </div>
      <ApiAccessRequestPopUp
        open={isDescriptionPopUpOpen}
        onClose={() => setIsDescriptionPopUpOpen(false)}
        isAdmin={true}
        description={currentRowData?.description}
      />
      <WaringPopup
        open={openPopup}
        close={() => {
          setOpenPopup(false);
        }}
        title={"Deny access"}
        text={"Are you sure want to deny product access?"}
        buttonLable="Yes"
        onAction={() => {
          changeAccess("DENIED", currentRowData);
          setOpenPopup(false);
        }}
      />
    </>
  );
}

export default ProductAccess;
