import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TrestleTable, { TableHeaderProps } from "../Shared/TrestleTable/TrestleTable";
import { setToast } from "../../app/toastReducer";
import { AppConstants } from "../../constants/AppConstants";
import { HTTP_STATUS } from "../../http/constants/http.status";
import { getPastQueryAnalysisRequests, submitQueryAnalysisRequest, getUserApiKeys } from "../../http/analyze-query";
import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DialogPopup from "../Shared/DialogPopup";
import Select from "react-select";
import TrestleButton from "../Shared/TrestleButton";
import {CircularGreenTickIcon, InQueueIcon} from "../../assets/svgs";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import DateRangeTrestle from "../Shared/DateRangePicker";



interface QueryHistoryProps {
  userId?: any;
  setLoading: Function;
}

function QueryAnalysis({ userId, setLoading }: QueryHistoryProps) {
  const dispatch = useDispatch();

  const [queryData, setQueryData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [refreshInterval, setRefreshInterval] = useState<any>(null);

  const getFormattedDate = (daysAgo: number = 0) => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo); //  Offset date by specified days
    return date.toISOString().split("T")[0]; //  Format YYYY-MM-DD
  };

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    apiKey: "",
    productName: "Real Contact 1.1",
    startDate: getFormattedDate(7), //  Default: 7 Days Ago
    endDate: getFormattedDate(0), //  Default: Today
  });

  const convertToISO = (date: string) => {
    if (!date) return "";
    
    // Check if already in YYYY-MM-DD format
    if (date.match(/^\d{4}-\d{2}-\d{2}$/)) return date;
  
    const dateParts = date.split("/");
    if (dateParts.length === 3) {
      return `${dateParts[2]}-${dateParts[0].padStart(2, "0")}-${dateParts[1].padStart(2, "0")}`;
    }
    return date;
  };
  

  const [apiKeyOptions, setApiKeyOptions] = useState<string[]>([]);
  const productOptions = ["Real Contact 1.1", "Real Contact 1.0", "Phone Intel 3.0"];

  useEffect(() => {
    fetchApiKeys();
    fetchQueryData(false);
  }, [pageNumber, pageSize]);

  useEffect(() => {
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
  
    // Check if any queries are in "PROCESSING" or "IN_QUEUE" state
    const hasProcessingQueries = queryData.some(
      (query) => query.status === "PROCESSING" || query.status === "IN_QUEUE"
    );
  
    if (hasProcessingQueries) {
      const interval = setInterval(() => {
        fetchQueryData(false);
      }, 5000); // Auto-refresh every 10 seconds
  
      setRefreshInterval(interval);
  
      return () => {
        clearInterval(interval);
      };
    }
  }, [queryData]);
  
  
  const fetchApiKeys = async () => {
    try {
      const response = await getUserApiKeys(userId);
      if (response.code === 200 && response.data?.apiKeys) {
        // Extract only the values (actual API keys)
        const keys: string[] = Object.values(response.data.apiKeys) as string[];
        setApiKeyOptions(keys);

      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: "Failed to fetch API keys.",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching API keys:", error);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: "Error fetching API keys.",
        })
      );
    }
  };
  

  const fetchQueryData = async (reloadScreen: boolean) => {
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
    if (reloadScreen) {
      setIsLoading(true);
      setLoading(true);
    }

    try {
      const response = await getPastQueryAnalysisRequests(userId, pageNumber, pageSize);

      if (response.status === HTTP_STATUS.HTTP_OK) {
        const { totalRecords = 0, data = [] } = response.data || {};
        setQueryData(data);
        setTotalRecords(totalRecords);
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: "Failed to fetch data.",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: "Error fetching data.",
        })
      );
    }

    if (reloadScreen) {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setOpenDialog(false);
  
    const { apiKey, productName, startDate, endDate } = formData;
  
    if (!startDate || !endDate) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: "Please select both Start Date and End Date.",
        })
      );
      return; //  Prevent submission if dates are missing
    }
  
    const formattedStartDate = convertToISO(startDate);
    const formattedEndDate = convertToISO(endDate);
  
    try {
      const response = await submitQueryAnalysisRequest(apiKey, productName, formattedStartDate, formattedEndDate);
  
      if (response.status === HTTP_STATUS.HTTP_OK) {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: "Query submitted successfully!",
          })
        );
  
        fetchQueryData(true);
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: "Failed to submit query.",
          })
        );
      }
    } catch (error) {
      console.error("Error submitting query:", error);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: "Error submitting query.",
        })
      );
    }
  };


    const [isFormComplete, setIsFormComplete] = useState(false);

    const checkIfFormComplete = (updatedForm: any) => {
    const { apiKey, productName, startDate, endDate } = updatedForm;
    setIsFormComplete(
        apiKey.trim() !== "" &&
        productName.trim() !== "" &&
        startDate.trim() !== "" &&
        endDate.trim() !== ""
    );
    };

  
  

  const handleDialogChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedForm = { ...prev, [name]: value };
      checkIfFormComplete(updatedForm); // Ensure the form is validated after updating
      return updatedForm;
    });
  };
  

  const headCells: TableHeaderProps[] = [
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At (UTC)",
      width: "14%",
      isCustomElements: false,
      customElements: () => null,
      sortable: true,
    },
    {
      id: "apiKey",
      numeric: false,
      disablePadding: false,
      label: "API Key",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => <>{ele.apiKey.slice(0, 5)}...</>,
      sortable: true,
    },
    {
      id: "productName",
      numeric: false,
      disablePadding: false,
      label: "Product Name",
      width: "16%",
      isCustomElements: false,
      customElements: () => null,
      sortable: true,
    },
    {
      id: "startDate",
      numeric: false,
      disablePadding: false,
      label: "Start Date",
      width: "13%",
      isCustomElements: true,
      customElements: (ele: any) => {
        // Convert from YYYYMMDD to YYYY-MM-DD
        const formattedDate = moment(ele.startDate, "YYYYMMDD").format("YYYY/MM/DD");
        return <span>{formattedDate}</span>;
      },
      sortable: true,
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      label: "End Date",
      width: "13%",
      isCustomElements: true,
      customElements: (ele: any) => {
        // Convert from YYYYMMDD to YYYY-MM-DD
        const formattedDate = moment(ele.endDate, "YYYYMMDD").format("YYYY/MM/DD");
        return <span>{formattedDate}</span>;
      },
      sortable: true,
    },    
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        width: "13%",
        isCustomElements: true,
        customElements: (ele: any) => {
            switch (ele.status) {
            case "COMPLETED":
                return (
                <span className="flex gap-1 items-center" style={{ color: "#00B47E" }}>
                    {CircularGreenTickIcon} Completed
                </span>
                );
            case "IN_QUEUE":
                return (
                <span className="flex gap-1 items-center" style={{ color: "#0494B4" }}>
                    {InQueueIcon} In Queue
                </span>
                );
            case "PROCESSING":
                return (
                    <span className="flex gap-1 items-center" style={{ color: "#007BFF" }}> {/* Changed to Blue */}
                    <CircularProgress size={16} color="inherit" /> Processing
                  </span>
                );

            case "FAILED":
                return (
                    <span className="flex gap-1 items-center" style={{ color: "#FF0000" }}> 
                        ❌ {ele.status} 
                    </span>
                );
            default:
                return (
                    <span className="flex gap-1 items-center" style={{ color: "#FF0000" }}> 
                        {ele.status} 
                    </span>
                );
            }
        },
        sortable: true,
    },
    {
      id: "totalRecords",
      numeric: true,
      disablePadding: false,
      label: "Usage",
      width: "10%",
      isCustomElements: false,
      customElements: () => null,
      sortable: true,
    },
    {
        id: "batchUrl",
        numeric: false,
        disablePadding: false,
        label: "Batch URL",
        width: "11%",
        isCustomElements: true,
        customElements: (ele: any) => (
            <a 
              href={ele.batchUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:opacity-80"
              style={{ color: "#0494B4" }}
            >
            Download
            </a>
        ),
        sortable: false,
    },
    {
        id: "reportUrl",
        numeric: false,
        disablePadding: false,
        label: "Report",
        width: "9%",
        isCustomElements: true,
        customElements: (ele: any) => (
            <a 
              href={ele.reportUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:opacity-80"
              style={{ color: "#0494B4" }}
            >
            View
            </a>
        ),
        sortable: false,
    },      
  ];

  return (
    <div className="w-full mt-5 bg-white p-6 min-h-[60vh]">
      {/* Buttons & Note Section */}
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-4 space-y-2 md:space-y-0">
        {/* Left Side: Note */}
        <p className="text-gray-500 text-sm md:mr-4">
            <strong>Note: </strong> 
             Maximum fetch is restricted to 250k records - Earliest to latest
        </p>

        {/* Right Side: Buttons in Flex Row */}
        <div className="flex items-center space-x-3">
            <TrestleButton
            onClick={() => setOpenDialog(true)}
            label="Analyze Query Set"
            type="primary"
            className="!h-[50px] !leading-[40px] !py-[20px] w-[180px]"
            />
        </div>
        </div>




        <DialogPopup
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        closeButton={true}
        dialogStyleClass="dialogSubmitQuery"
        title=""
        className="!p-0 w-[700px]"
        component={
            <div className="p-6">
            {/* Dialog Header */}
            <div className="w-full py-6 px-8 bg-[#F3F5F6] text-[#163545] mb-4">
                <div className="text-xl font-bold">Fetch Query data from Network Database</div>
            </div>

            {/* Dialog Body */}
            <div className="p-6 w-full text-lg space-y-4">
                <p className="text-gray-700 text-base">
                Please fill in the details to submit a new request.
                </p>

                {/* API Key Dropdown */}
                <div className="w-full">
                <label className="block text-gray-600 mb-1 text-sm font-medium">API Key</label>
                <Select
                    options={apiKeyOptions.map((key) => ({ label: key, value: key }))}
                    placeholder="Select API Key"
                    value={
                    apiKeyOptions.find((key) => key === formData.apiKey)
                        ? { label: formData.apiKey, value: formData.apiKey }
                        : null
                    }
                    onChange={(selectedOption: any) => {
                    setFormData({ ...formData, apiKey: selectedOption?.value || "" });
                    }}
                    className="select-dropdown"
                />
                </div>

                {/* Product Name Dropdown */}
                <div className="w-full">
                <label className="block text-gray-600 mb-1 text-sm font-medium">Product Name</label>
                <Select
                    options={productOptions.map((product) => ({
                    label: product,
                    value: product,
                    }))}
                    placeholder="Select Product"
                    value={
                    productOptions.find((product) => product === formData.productName)
                        ? { label: formData.productName, value: formData.productName }
                        : null
                    }
                    onChange={(selectedOption: any) => {
                    setFormData({
                        ...formData,
                        productName: selectedOption?.value || "",
                    });
                    }}
                    className="select-dropdown"
                />
                </div>

                <div className="w-full">
                  <DateRangeTrestle
                    value={{
                      startDate: formData.startDate ? new Date(formData.startDate) : new Date(),
                      endDate: formData.endDate ? new Date(formData.endDate) : new Date(),
                    }}
                    setValue={(range: { startDate: Date; endDate: Date }) => {
                      setFormData((prev) => ({
                        ...prev,
                        startDate: moment(range.startDate).format("YYYY-MM-DD"),
                        endDate: moment(range.endDate).format("YYYY-MM-DD"),
                      }));
                    }}
                  />
                </div>

            </div>

            {/* Dialog Footer - Buttons */}
            <div className="flex justify-end gap-4 p-2 border-t border-gray-200">
                <TrestleButton
                onClick={() => setOpenDialog(false)}
                label="Cancel"
                type="secondary"
                className="max-w-[180px] !h-[40px]"
                />
                <TrestleButton
                onClick={handleSubmit}
                label="Submit"
                type="primary"
                className="max-w-[180px] !h-[40px]"
                disabled={!formData.apiKey || !formData.productName || !formData.startDate || !formData.endDate} 
                />
            </div>
            </div>
        }
        closeOnOut={true}
        />


    


      {/* Table */}
      <TrestleTable
        isPaginated={true}
        headCells={headCells}
        rows={queryData}
        clientSort={true}
        setPageNumber={setPageNumber}
        totalNoOfRecords={totalRecords}
        setNoOfRecords={setPageSize}
      />
    </div>
  );
}

export default QueryAnalysis;
