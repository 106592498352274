import React, { useEffect, useState } from "react";
import DialogPopup from "../DialogPopup";
import { Box, Stack, Typography } from "@mui/material";
import TrestleButton from "../TrestleButton";
import InputTextField from "../InputTextField";
import { AppConstants } from "../../../constants/AppConstants";
import { Text16pxBold, Text16pxMedium } from "../CommonComponents";

interface ApiAccessRequestPopUpProps {
  open: boolean;
  onClose: () => void;
  handleRequestAccess?: (formData: any) => void;
  isAdmin?: boolean;
  description?: string;
  requestSentSuccessfully?: boolean;
}

const ApiAccessRequestPopUp: React.FC<ApiAccessRequestPopUpProps> = ({
  open = false,
  onClose = () => {},
  handleRequestAccess = () => {},
  isAdmin = false,
  description = "",
  requestSentSuccessfully = false,
}) => {
  const [formData, setFormData] = useState({ description: "" });
  const [formError, setFormError] = useState({ description: "" });

  useEffect(() => {
    if (isAdmin) {
      setFormData({ description: description });
    }
  }, [description]);

  const OnDataChange = (event: any) => {
    const { value, name } = event.target;
    let val: any = value;
    setFormData({
      ...formData,
      [name]: val,
    });
  };

  const handleValidation = () => {
    const { description = "" } = formData || {};
    let isError: boolean = true;

    if (description.trim().length < 100) {
      setFormError({ description: "Please write at least 100 characters" });
      isError = true;
    } else {
      setFormError({ description: "" });
      isError = false;
    }

    return isError;
  };

  const handleSubmit = () => {
    if (!handleValidation()) {
      handleRequestAccess(formData);
    }
    return;
  };

  const handleClose = () => {
    onClose();
    if (!isAdmin) {
      setFormData({ description: "" });
      setFormError({ description: "" });
    }
  };

  return (
    <div>
      <DialogPopup
        open={open}
        onClose={handleClose}
        className="!p-0 w-[700px]"
        component={
          <>
            {requestSentSuccessfully ? (
              <>
                <Box className="flex flex-col mt-[20px] py-8 px-10 gap-[48px]">
                  <Stack spacing={2}>
                    <div className="text-xl font-bold flex text-[#163545]">
                      Request Submitted!
                    </div>

                    <Typography>
                      A Trestle employee will review your submission, and you
                      will be notified within 48 hours.
                    </Typography>
                  </Stack>
                </Box>
                <Box className="flex gap-[10px] py-8 px-10 justify-end">
                  <TrestleButton
                    onClick={handleClose}
                    label="Continue"
                    type="primary"
                    className="!h-[40px]!min-w-[fit-content] !w-fit"
                  />
                </Box>
              </>
            ) : (
              <>
                <Box className="flex flex-col mt-[20px] py-8 px-10 gap-[48px]">
                  <Stack spacing={2}>
                    <div className="text-xl font-bold flex text-[#163545]">
                      API Access Request
                    </div>

                    <Typography>
                      Trestle requires this information in order to ensure
                      responsible data usage and stay in compliance with data
                      regulations.
                    </Typography>
                  </Stack>

                  <Stack spacing={2}>
                    <Text16pxBold c={"#163545"}>
                      Please describe how you plan to use this data.
                    </Text16pxBold>
                    <InputTextField
                      name={"description"}
                      label={""}
                      placeholder="We are a online lending service, looking to verify the person/business details behind the phone number used to sign up before contacting them."
                      value={formData}
                      error={formError}
                      type={"text"}
                      onChange={OnDataChange}
                      required={true}
                      maxLength={AppConstants.MAX_NAME_LENGTH}
                      multiLine={true}
                      minRows={5}
                      className="!h-fit !p-[10px]"
                      disabled={isAdmin}
                    />
                  </Stack>
                </Box>

                <Box className="flex gap-[10px] py-8 px-10 justify-end">
                  {isAdmin ? (
                    <>
                      <TrestleButton
                        onClick={handleClose}
                        label="Done"
                        type="primary"
                        className="!h-[40px]!min-w-[fit-content] !w-fit"
                      />
                    </>
                  ) : (
                    <>
                      <TrestleButton
                        onClick={handleClose}
                        label="Cancel"
                        type="secondry"
                        className="!h-[40px]!min-w-[fit-content] !w-fit"
                      />
                      <TrestleButton
                        onClick={handleSubmit}
                        label="Request Access"
                        type="primary"
                        className="!h-[40px]!min-w-[fit-content] !w-fit"
                        disabled={!formData.description}
                      />
                    </>
                  )}
                </Box>
              </>
            )}
          </>
        }
      />
    </div>
  );
};

export default ApiAccessRequestPopUp;
